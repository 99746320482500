<template>
  <RecordViewWrapper>
    <sdPageHeader title="Realisasi Kinerja IT (Progrees RJPP)">
      <template #subTitle>
        <sdButton
          class="btn-add_new"
          size="default"
          type="primary"
          v-if="checkPermission('create laporan manajemen realisasi kinerja it (progrees rjpp)')"
        >
          <router-link to="/laporan-manajemen/progress-rjpp/add">
            <sdFeatherIcons type="plus" size="14" /> Tambah</router-link
          >
        </sdButton>
      </template>
    </sdPageHeader>

    <Main>
      <a-row :gutter="15">
        <a-col class="w-100" :md="24">
          <sdCards headless>
            <div v-if="isLoading" class="spin">
              <a-spin />
            </div>

            <div v-else>
              <TableWrapper class="table-data-view table-responsive">
                <a-table :dataSource="dataSource" :columns="columns.datas" :pagination="false" bordered />
              </TableWrapper>
            </div>
          </sdCards>
        </a-col>
      </a-row>

      <a-modal v-model:visible="modalState.visible" title="Konfirmasi">
        <a-form
          layout="horizontal"
          :model="formModalState"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          labelAlign="left"
          :rules="modalRules"
          id="unapproveForm"
          @finish="doUnapprove"
        >
          Apakah Anda yakin ingin melakukan unapprove realisasi kinerja {{ modalState.titleKeterangan }}?
          <a-form-item name="alasan" label="Alasan">
            <a-input v-model:value="formModalState.alasan" placeholder="Masukkan Alasan" />
          </a-form-item>
        </a-form>
        <template #footer>
          <a-button key="back" @click="handleCancel">Batal</a-button>
          <a-button class="sDash_form-action__btn" type="primary" size="large" htmlType="submit" form="unapproveForm">
            {{ modalState.isLoading ? 'Harap Menunggu...' : 'Unapprove' }}
          </a-button>
        </template>
      </a-modal>
    </Main>
  </RecordViewWrapper>
</template>

<script>
import { RecordViewWrapper } from '../../../components/crud/style';
import { computed, onMounted, reactive } from 'vue';
import { Main, TableWrapper } from '../../styled';
import { useStore } from 'vuex';
import { Modal } from 'ant-design-vue';
import { DataService } from '@/config/dataService/dataService';
import { notification } from 'ant-design-vue';
import { getItem } from '@/utility/localStorageControl';

const ViewPage = {
  name: 'ViewPage',
  components: { RecordViewWrapper, Main, TableWrapper },
  setup() {
    const { state, dispatch } = useStore();
    const crud = computed(() => state.crud.data);
    const isLoading = computed(() => state.crud.loading);
    const profile = getItem('profile_plnbb');
    const permissions = getItem('permissions');

    const customRenderRjpp = row => {
      if (row.record.rjpp_sequence == 1) {
        return {
          children: row.text,
          props: {
            rowSpan: row.record.jumlah_progress == 0 ? 1 : row.record.jumlah_progress,
          },
        };
      }
      return {
        children: row.text,
        props: {
          rowSpan: 0,
        },
      };
    };

    const customRenderUraian = row => {
      if (row.record.uraian_sequence == 1) {
        return {
          children: row.text,
          props: {
            rowSpan: row.record.jumlah_uraian_progress == 0 ? 1 : row.record.jumlah_uraian_progress,
          },
        };
      }
      return {
        children: row.text,
        props: {
          rowSpan: 0,
        },
      };
    };

    const columns = reactive({
      datas: [],
    });

    const modalState = reactive({
      isLoading: false,
      visible: false,
      titleKeterangan: '',
    });

    const formModalState = reactive({
      id: null,
      alasan: '',
    });

    const labelCol = {
      lg: 6,
      md: 9,
      xs: 24,
    };

    const wrapperCol = {
      lg: 18,
      md: 15,
      xs: 24,
    };

    onMounted(() => {
      getData();
    });

    const getData = () => {
      dispatch('axiosCrudGetData', 'laporan-progress-rjpp').then(() => {
        columns.datas = [
          {
            title: 'RJPP',
            dataIndex: 'rjpp',
            customRender: customRenderRjpp,
          },
          {
            title: 'Uraian',
            dataIndex: 'uraian',
            customRender: customRenderUraian,
          },
        ];

        crud.value.data.years.forEach(year => {
          let generateChilds = crud.value.data.stages.map(stage => {
            return {
              title: stage.toUpperCase(),
              dataIndex: year + '_' + stage,
              align: 'center',
            };
          });

          columns.datas.push({
            title: year,
            dataIndex: year,
            align: 'center',
            children: generateChilds,
          });
        });

        columns.datas.push({
          title: 'Keterangan',
          dataIndex: 'keterangan',
        });
      });
    };

    const handleDelete = (id, name) => {
      Modal.confirm({
        title: 'Konfirmasi',
        content: `Apakah Anda yakin ingin menghapus ${name}?`,
        okText: 'Hapus',
        cancelText: 'Batal',
        onOk: () => {
          dispatch('axiosDataDelete', {
            id,
            url: 'laporan-progress-rjpp',
            getData: () => {
              dispatch('axiosCrudGetData', 'laporan-progress-rjpp');
            },
          });
        },
      });
    };

    const formState = reactive({
      searchItem: '',
    });

    const dataSource = computed(() => {
      if (crud.value && crud.value.data && crud.value.data.years) {
        let lists = crud.value.data.lists.map((item, key) => {
          crud.value.data.years.forEach(year => {
            let iconCheck = <sdFeatherIcons type="check" size="14" />;
            if (item.tahun == year) {
              item[year + '_tw1'] = item.tw1 ? iconCheck : '';
              item[year + '_tw2'] = item.tw2 ? iconCheck : '';
              item[year + '_tw3'] = item.tw3 ? iconCheck : '';
              item[year + '_tw4'] = item.tw4 ? iconCheck : '';
            } else {
              item[year + '_tw1'] = '';
              item[year + '_tw2'] = '';
              item[year + '_tw3'] = '';
              item[year + '_tw4'] = '';
            }
          });
          item.key = key + 1;
          let titleRjpp = item.rjpp;

          let updateItem = checkPermission('update laporan manajemen realisasi kinerja it (progrees rjpp)') ? (
            <>
              <router-link class="edit" to={`/laporan-manajemen/progress-rjpp-edit/${item.rjpp_id}`}>
                <sdFeatherIcons type="edit" size={14} title="Ubah" />
              </router-link>
              &nbsp;&nbsp;&nbsp;
            </>
          ) : null;

          let delItem = checkPermission('delete laporan manajemen realisasi kinerja it (progrees rjpp)') ? (
            <router-link class="delete" onClick={() => myFun.handleDeleteRjpp(item.rjpp_id, titleRjpp)} to="">
              <sdFeatherIcons type="trash-2" size={14} title="Hapus" />
            </router-link>
          ) : null;

          item.rjpp = (
            <div>
              {item.rjpp} &nbsp;&nbsp;&nbsp;
              {updateItem}
              {delItem}
            </div>
          );

          let titleUraian = item.uraian;

          let delUraian = checkPermission('delete laporan manajemen realisasi kinerja it (progrees rjpp)') ? (
            <router-link class="delete" onClick={() => myFun.handleDeleteUraian(item.uraian_id, titleUraian)} to="">
              <sdFeatherIcons type="trash-2" size={14} title="Hapus" />
            </router-link>
          ) : null;

          let deleteUraianBtn = item.jumlah_uraian > 1 ? delUraian : <span></span>;

          let updateUraian = checkPermission('update laporan manajemen realisasi kinerja it (progrees rjpp)') ? (
            <>
              <router-link class="edit" to={`/laporan-manajemen/progress-rjpp-uraian-edit/${item.uraian_id}`}>
                <sdFeatherIcons type="edit" size={14} title="Ubah" />
              </router-link>
              &nbsp;&nbsp;&nbsp;
            </>
          ) : null;

          item.uraian = (
            <div>
              {item.uraian} &nbsp;&nbsp;&nbsp;
              {updateUraian} {deleteUraianBtn}
            </div>
          );

          if (item.progress_id != null) {
            let titleKeterangan = item.keterangan;

            let color;

            switch (item.status) {
              case 'CRTD':
                color = 'blue';
                break;
              case 'UPDT':
                color = 'orange';
                break;
              case 'REL':
                color = 'green';
                break;
              case 'UNREL':
                color = 'volcano';
                break;
              case 'DEL':
                color = 'red';
                break;
            }

            let update = checkPermission('update laporan manajemen realisasi kinerja it (progrees rjpp)') ? (
              <router-link class="edit" to={`/laporan-manajemen/progress-rjpp-progress-edit/${item.progress_id}`}>
                <sdFeatherIcons type="edit" size={14} title="Ubah" />
              </router-link>
            ) : null;

            let approve = checkPermission('approve laporan manajemen realisasi kinerja it (progrees rjpp)') ? (
              <a class="edit" onClick={() => handleApprove(item.progress_id, titleKeterangan)}>
                <sdFeatherIcons type="check-square" size={14} title="Approve" />
              </a>
            ) : null;

            let del = checkPermission('delete laporan manajemen realisasi kinerja it (progrees rjpp)') ? (
              <router-link
                class="delete"
                onClick={() => myFun.handleDeleteProgress(item.progress_id, titleKeterangan)}
                to=""
              >
                <sdFeatherIcons type="trash-2" size={14} title="Hapus" />
              </router-link>
            ) : null;

            let unapprove = checkPermission('approve laporan manajemen realisasi kinerja it (progrees rjpp)') ? (
              <a class="edit" onClick={() => handleUnapprove(item.progress_id, titleKeterangan)}>
                <sdFeatherIcons type="rotate-ccw" size={14} title="Unapprove" />
              </a>
            ) : null;

            item.keterangan = (
              <div>
                {item.keterangan} &nbsp;&nbsp;&nbsp;
                <a-tag color={color}>{item.status}</a-tag> &nbsp;&nbsp;&nbsp;
                {item.status != 'REL' ? (
                  <>
                    {update}
                    {approve}
                    {del}
                  </>
                ) : (
                  { unapprove }
                )}
              </div>
            );
          }
          return item;
        });
        return lists;
      }
      return [];
    });

    const handleSearch = () => {
      dispatch('axiosDataSearch', { url: 'laporan-progress-rjpp', filter: formState.searchItem });
    };

    class Fun {
      handleDeleteRjpp = (rjpp_id, name) => {
        Modal.confirm({
          title: 'Konfirmasi',
          content: `Apakah Anda yakin ingin menghapus${name}?`,
          okText: 'Hapus',
          cancelText: 'Batal',
          onOk: () => {
            dispatch('axiosDataDelete', {
              id: rjpp_id,
              url: 'laporan-progress-rjpp',
              getData: () => {
                dispatch('axiosCrudGetData', 'laporan-progress-rjpp');
              },
            });
          },
        });
      };

      handleDeleteUraian = (uraian_id, title) => {
        Modal.confirm({
          title: 'Konfirmasi',
          content: `Apakah Anda yakin ingin menghapus ${title}?`,
          okText: 'Hapus',
          cancelText: 'Batal',
          onOk: () => {
            dispatch('axiosDataDelete', {
              id: uraian_id,
              url: 'laporan-progress-rjpp-uraian',
              getData: () => {
                dispatch('axiosCrudGetData', 'laporan-progress-rjpp');
              },
            });
          },
        });
      };

      handleDeleteProgress = (progress_id, title) => {
        Modal.confirm({
          title: 'Konfirmasi',
          content: `Apakah Anda yakin ingin menghapus ${title}?`,
          okText: 'Hapus',
          cancelText: 'Batal',
          onOk: () => {
            dispatch('axiosDataDelete', {
              id: progress_id,
              url: 'laporan-progress-rjpp-progress',
              getData: () => {
                dispatch('axiosCrudGetData', 'laporan-progress-rjpp');
              },
            });
          },
        });
      };
    }

    const myFun = new Fun();

    const handleApprove = (id, titleKeterangan) => {
      Modal.confirm({
        title: 'Konfirmasi',
        content: `Apakah Anda yakin ingin melakukan approve realisasi kinerja ${titleKeterangan}?`,
        okText: 'Approve',
        cancelText: 'Batal',
        onOk: () => {
          dispatch('axiosDataApprove', {
            id,
            url: 'approve-laporan-progress-rjpp',
            getData: () => {
              getData();
            },
          });
        },
      });
    };

    const handleUnapprove = (id, titleKeterangan) => {
      formModalState.id = id;
      modalState.titleKeterangan = titleKeterangan;
      modalState.visible = true;
    };

    const modalRules = {
      alasan: [
        {
          required: true,
          message: 'Harap Diisi',
          trigger: 'blur',
        },
      ],
    };

    const handleCancel = () => {
      modalState.visible = false;
    };

    const doUnapprove = () => {
      modalState.isLoading = true;

      DataService.post('unapprove-laporan-progress-rjpp/' + formModalState.id, { alasan: formModalState.alasan }).then(
        response => {
          modalState.visible = false;
          modalState.isLoading = false;
          formModalState.id = null;
          formModalState.alasan = '';
          notification.success({
            message: response.data.message,
          });

          getData();
        },
      );
    };

    const checkPermission = permission => {
      if (profile.roles[0].name == 'Super Admin') {
        return true;
      }

      return permissions.includes(permission);
    };

    return {
      formState,
      columns,
      isLoading,
      crud,
      dataSource,
      handleDelete,
      handleSearch,
      myFun,
      handleApprove,
      handleUnapprove,
      labelCol,
      wrapperCol,
      modalState,
      handleCancel,
      doUnapprove,
      formModalState,
      modalRules,
      checkPermission,
    };
  },
};

export default ViewPage;
</script>
